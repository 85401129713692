<div class="all-vendor-main-container altering-container justify-content-start">
  <div class="title-container p-3 bg-darkgray-color sticky-top" >
      <button class="unfilled-blue-btn p-1 align-item-center d-flex" *ngIf="hideActionsBasedOnRoleType" [routerLink]="['/allusers']"><ion-icon name="chevron-back-outline"></ion-icon> All Users</button>
      <h3 class="title text-success">User Details</h3>
      <div class="button-container" >
        <div class="button-container">
          <button class="btn btn-primary" (click)="changeThePassword()">Change Password</button>
          </div>
          <div class="button-container" >
          <button class="btn btn-primary"  [routerLink]="['/edituser', userData.UserId]">Edit</button>
          </div>
      </div> 
  </div>

  <ngx-loading [show]="loading" [config]="{ primaryColour: '#0e4f98', secondaryColour: '#0e4f98', tertiaryColour: '#0e4f98' }"></ngx-loading>


  <div *ngIf="!loading" class="form-inner-block-container p-2">
      <div class="py-1 px-3 d-flex">
          <h3 class="text-dark fw-bolder me-5">User ID</h3>
          <h3 class="text-dark">{{userData.UserId}}</h3>
      </div>  
      <hr>     
      <div class="form-inner-block-container p-2">
        <div class="py-2">
            <table class="table table-borderless mb-0">
            <colgroup>
                <col span="1" style="width:300px;">
            </colgroup>
                <tr>
                  <td class="fw-bold">Full Name</td>
                  
                  <td>{{userData?.FirstName}} {{userData?.LastName}}</td>
                </tr>
                <tr>
                    <td class="fw-bold">Email</td>
                    <td>{{userData?.Email}}</td>
                </tr>
                <tr>
                  <td class="fw-bold">Mobile Number</td>
                  <td>{{userData?.PhoneNumber}}</td>
              </tr>
              <tr>
                <td class="fw-bold">User Role</td>
                <td *ngIf="userData.UserRole == '1'">Super Admin</td>
                <td *ngIf="userData.UserRole == '2'">HO Admin</td>
                <td *ngIf="userData.UserRole == '3'">Site Admin</td>
              </tr>
              <tr>
                <td class="fw-bold">Requested By</td>
                <td>{{userData?.CreatedBy}} | {{userData?.CreatedOn}}</td>
              </tr>
              <tr *ngIf="userData.UpdatedBy">
                <td class="fw-bold">Updated By</td>
                <td>{{userData?.UpdatedBy}} | {{userData?.UpdatedOn}}</td>
              </tr>

            </table>
        </div>                   
    </div>


  </div>
  <hr>



</div>

<app-change-password ></app-change-password>

