import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../shared/global.service';
import { BusinessService } from 'src/app/business.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleType } from 'src/app/enums/role-type.enum';
import { DataSharingService } from '../../shared/data-sharing.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {

  loading: boolean = true;
  userId: any;
  userData: any;
  loggedInUserId: any;

  // checking user role 
  loggedInUserRoleType: string | null = null;
  hideActionsBasedOnRoleType: boolean = false;


  constructor(
    private globalService: GlobalService,
    private businessService: BusinessService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private dataSharing: DataSharingService
  ) { }

  ngOnInit() {
    this.loggedInUserId = localStorage.getItem("UserId");
    this.loggedInUserRoleType = localStorage.getItem("RoleType")
    this.userId = this.activateRoute.snapshot.paramMap.get("userId");

    if (this.loggedInUserRoleType == RoleType.Site_Admin) {
      this.hideActionsBasedOnRoleType = true;
    }


    this.businessService.getUser(this.userId).subscribe((data) => {
      this.userData = data[0];
      this.loading = false;
    })


    if (this.loggedInUserId != this.userId) {
      this.dataSharing.setDifferentUserPasswordChange(true, this.userId);
    }
    else {
      this.dataSharing.setDifferentUserPasswordChange(false, this.userId);
    }

  }

  changeThePassword() {
    
    this.dataSharing.setChangePassword(true);
    this.dataSharing.setPopupVisibility(true);
  }

}
