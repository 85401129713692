<nav class="navbar navbar-expand-lg navbar-light bg-light px-2 px-md-5">
  <div class="container-fluid">
    <a class="navbar-brand" href="#"><img src="assets/images/logo.png" alt="" class="nav-logo"></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav m-auto mb-2 mb-lg-0">
        <!-- <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">About Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Our Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Projects</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Contact Us</a>
        </li> -->
      </ul>

    </div>
  </div>
</nav>

<section class=" login-main-container utility-background-property vendor-section-main-container container mx-0  mw-100">
  <div class="row h-100 px-2 px-md-3 px-lg-5 login-row">
    <div class="col-sm-12 col-md-6 col-lg-7 login-banner">
      <div class="h-50">
        <h1 class="fs-1 fw-bolder text-light">ReyMould Technology Solutions</h1>
      </div>
      <div>
        <p class="text-light" style="visibility: hidden;">BAC infratech Pvt Ltd specializes in installing water supply pipelines across
          diverse locations, ensuring efficient water distribution and accessibility.</p>
      </div>
    </div>

    <div class="col px-md-5">
      <div class="card p-3 m-auto w-100">
        <div class="card-title text-center theme-color-blue fs-4">Login</div>

        <!-- Login form start -->
        <div class="card-body py-0">
          <form [formGroup]="loginForm" id="bac-login-form">
            <div class="form-group">
              <label for="staticEmail" class="col-form-label">Email</label>
              <div class="">
                <input type="text" class="form-control blue-border bg-transparent" id="username"
                  formControlName="username" placeholder="Enter Your Email ID">
                 
              </div>
            </div>
            <div class="form-group">
              <label for="inputPassword" class="col-form-label">Password</label>
              <div class="">
                <input type="{{ password ? 'text' : 'password' }}" class="form-control blue-border bg-transparent" id="secretkey"
                  formControlName="secretkey" placeholder="Password">
                  <div style="position: relative;">
                    <ion-icon class="eye-icon" name="{{ password ? 'eye-outline' : 'eye-off-outline' }}"
                        (click)="togglePasswordVisibility()"></ion-icon>
                    </div>
              </div>

            </div>
            <p *ngIf="invalidLogin" class="invalid-details">Invalid login credentials</p>
            <div class="form-group d-flex justify-content-between w-100 my-1">
              <div class="d-flex align-item-center">
                <input type="checkbox" name="" id="rememberMe" class="me-2">
                <label for="rememberMe">Remember Me</label>
              </div>
              <div>
                <a style="color: #4285F4;cursor: pointer;"  (click)="forgotPassword()">Forgot Password?</a>
              </div>
            </div>

            <div class="form-group">
              <button class="btn btn-primary w-100" type="submit" (click)="validateLogin()">Login</button>
            </div>
            <!-- <div class="py-2">
              <p class="fs-8">Don't Have An Account? <a href="#" class="text-danger">Signup Here</a>
              </p>
            </div> -->
          </form>
        </div>
        <!-- Login form end -->
      </div>

    </div>
  </div>
</section>