import { Injectable } from '@angular/core';
import { PDFDocument, rgb } from 'pdf-lib';

@Injectable({
  providedIn: 'root'
})
export class FileServiceService {

  constructor() { }

  async base64toPDF(base64Data: string, filename: string): Promise<Blob> {
    const bytes = this.base64ToUint8Array(base64Data);
    const pdfDoc = await PDFDocument.load(bytes);

    const pdfBytes = await pdfDoc.save();
    return new Blob([pdfBytes], { type: 'application/pdf' });
  }

  private base64ToUint8Array(base64: string): Uint8Array {
    const byteCharacters = atob(base64);
    const byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }
    return byteArray;
  }

  async genarateUrlFromByteArray(base64Data: string, filename: string) {
    const blob = await this.base64toPDF(base64Data, filename);
    return window.URL.createObjectURL(blob);
  }

}