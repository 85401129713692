import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {

  private _password = new BehaviorSubject<string>('');
  password$ = this._password.asObservable();

  private _isPopupVisible = new BehaviorSubject<boolean>(false);
  isPopupVisible$ = this._isPopupVisible.asObservable();

  private _changePassword = new BehaviorSubject<boolean>(false);
  _changePassword$ = this._changePassword.asObservable();

  private _isDifferentUserPasswordChange = new BehaviorSubject<boolean>(false);
  _isDifferentUserPasswordChange$ = this._isDifferentUserPasswordChange.asObservable();

  private _differentUserPasswordChangeUserId = new BehaviorSubject<string>('');
  differentUserPasswordChangeUserId$ = this._differentUserPasswordChangeUserId.asObservable();

  selectedVendor: any;

  private vendorsDataSubject = new BehaviorSubject<any>(null);
  vendorsData$ = this.vendorsDataSubject.asObservable();

  constructor() { }

  setPassword(password: string) {
    this._password.next(password);
  }

  getPassword(): string {
    return this._password.value;
  }




  setPopupVisibility(value: boolean) {
    this._isPopupVisible.next(value);
  }

  setDifferentUserPasswordChange(isDifferentUserPasswordChange: boolean, message: string) {
    this._isDifferentUserPasswordChange.next(isDifferentUserPasswordChange);
    this._differentUserPasswordChangeUserId.next(message);
  }

  setDifferentUserPasswordChangeToFalse(isDifferentUserPasswordChange: boolean){
    this._isDifferentUserPasswordChange.next(isDifferentUserPasswordChange);
  }

  setChangePassword(value: boolean) {
    this._changePassword.next(value);
  }


  setVendorsData(data: any) {
    this.vendorsDataSubject.next(data);

  }

  getVendorsData() {
    return this.vendorsDataSubject.value;
  }


  setSelectedVendor(vendor: any) {
    this.selectedVendor = vendor;
  }

  getSelectedVendor() {

    return this.selectedVendor;
  }


}
