import { Injectable } from '@angular/core';
import { AlertController, IonicSafeString } from '@ionic/angular';
import { ValidatorFn, AbstractControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { StatusType } from 'src/app/enums/status.enum';
import { BusinessService } from 'src/app/business.service';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  downloadAttachedfileName: any;

  constructor( private alertController: AlertController,private sanitizer: DomSanitizer, private businessService : BusinessService) { }

  //POP up alert message
  public async customAlert(heading: string, message : string) {
    const alert = await this.alertController.create({
      header: heading,
      message: message,
      buttons: ['OK'],
      cssClass: 'alert-message-main'
    });
    await alert.present();
  }

   //POP up alert message
   public async errorAlert(heading: string, message : string) {
    const alert = await this.alertController.create({
      header: heading,
      message: message,
      buttons: ['OK'],
      cssClass: 'alert-message-main-error'
    });
    await alert.present();
  }

  // public confirmAction(message: string){
  //   let flag = confirm(message);
  //   return flag;
  // }

  public async confirmAction(message: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      const alert = await this.alertController.create({
        header: 'Confirm',
        message: message,
        cssClass: 'confirm-message-main',
        buttons: [
          {
            text: 'CANCEL',
            role: 'cancel',
            handler: () => resolve(false),
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: () => resolve(true),
          },
        ],
      });

      await alert.present();
    });
  }

  getStatusText(status: string): string {
    if (status === StatusType.Pending) {
      return "Pending";
    } else if (status === StatusType.Approved) {
      return "Approved";
    } else if (status === StatusType.Inactive) {
      return "Inactive";
    } else {
      return ""; // Handle other status values if needed
    }
  }



  downloadApprovedDocument(orderNumber: string, orderType: string){
    
    

    this.businessService.downloadUploadedDocument(orderType, orderNumber).subscribe((response) => {
      if(response && response.length > 0)
      {
      let parsedData;
      
      if(orderType == "purchaseorder")
      {
        parsedData = JSON.parse(response[0].PurchaseOrderAttachment);
      }
      else
      {
        parsedData = JSON.parse(response[0].WorkOrderAttachment);
      }

     
      this.downloadAttachedfileName = parsedData.fileName;
   
      const fileType = parsedData.fileType;
      const byteArray = new Uint8Array(parsedData.byteArray);
      
      // Create a Blob from the byte array
      const blob = new Blob([byteArray], { type: fileType });
  
      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);
  
      // Create a temporary anchor element
      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = this.downloadAttachedfileName; // Set the file name
      anchor.click();
  
      // Cleanup
      URL.revokeObjectURL(blobUrl);
    }
    else
    {
      this.errorAlert("Error", "there is no document attachment to this order")
    }
  });

  


  }

  



  //////////////////////////
  // Form field validations
  //////////////////////////
  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const emailRegex = /@.*\.(com|in|co\.in|gov\.in|nic\.in|ac\.in|org\.in|org|net|info|biz)$/;
      const isValid = emailRegex.test(control.value.trim()); // Check if the value matches the regex
      return isValid ? null : { 'invalidEmail': { value: control.value } };
    };
  }


  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
      // Regular expression explanation:
      // ^                  Start of string
      // (?=.*[a-z])        At least one lowercase letter
      // (?=.*[A-Z])        At least one uppercase letter
      // (?=.*\d)           At least one digit
      // (?=.*[!@#$%^&*])   At least one special character
      // [A-Za-z\d!@#$%^&*]{8,}  Match characters from the given set (alphanumeric and special) of at least length 8
      // $                  End of string
  
      const isValid = passwordRegex.test(control.value.trim()); // Check if the value matches the regex
      return isValid ? null : { 'invalidPassword': { value: control.value } };
    };
  }

  noSpacesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const hasSpaces = (control.value || '').toString().trim().includes(' ');
      return hasSpaces ? { 'noSpaces': true } : null;
    };
  }


  maxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && value.length > maxLength) {
        return { 'maxLengthExceeded': { maxLength: maxLength, actualLength: value.length } };
      }
      return null;
    };
  }

  gstNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const gstNumber = control.value.trim();
      if (gstNumber && gstNumber.trim() !== '' && gstNumber.length !== 15) {
        return { 'invalidGstNumberLength': true };
      }
      return null;
    };
  }

  panCardNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const gstNumber = control.value.trim();
      if (gstNumber && gstNumber.length !== 10) {
        return { 'invalidPanCard': true };
      }
      return null;
    };
  }


  NotRequriedemailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) {
        // If the value is empty, consider it valid (no error)
        return null;
      }
  
      // Regular expression to match emails with specified domains
      const emailRegex = /@.*\.(com|in|co\.in|gov\.in|nic\.in|ac\.in|org\.in|org|net|info|biz)$/;
      const isValid = emailRegex.test(value.trim()); // Check if the value matches the regex
      return isValid ? null : { 'invalidEmailid': { value } };
    };
  }

  accountNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const accountNumberRegex = /^[a-zA-Z0-9]{8,34}$/; // Regular expression to match 11 to 16-digit numbers
      const isValid = accountNumberRegex.test(control.value.trim()); // Check if the value matches the regex
      return isValid ? null : { 'invalidAccountNumber': { value: control.value.trim() } };
    };
  }

  phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const phoneNumberRegex = /^\d{8,13}$/; // Regular expression to match 8 to 13 digit numbers
      const isValid = phoneNumberRegex.test(control.value.trim()); // Check if the value matches the regex
      return isValid ? null : { 'invalidPhoneNumber': { value: control.value.trim() } };
    };
  }

  pinCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const pinCodeRegex = /^\d{6}$/; // Regular expression to match 1 to 6 digits
      const isValid = pinCodeRegex.test(control.value); // Check if the control's value matches the regex
      return isValid ? null : { 'invalidPinCode': { value: control.value } };
    };
  }

  aadharValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // Regular expression to match exactly 12 digits or allow for an empty string
      const aadharRegex = /^(|\d{12})$/; 
      const isValid = aadharRegex.test(control.value.trim());
      return isValid ? null : { 'invalidAadhar': { value: control.value.trim() } };
    };
  }

  IfscCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // Regular expression to match exactly 11 characters consisting of alphanumeric characters
      const elevenDigitRegex = /^[a-zA-Z0-9]{11}$/;
      const isValid = elevenDigitRegex.test(control.value.trim());
      return isValid ? null : { 'invalidIFSCCode': { value: control.value.trim() } };
    };
  }
  
}
