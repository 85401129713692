import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { BusinessService } from 'src/app/business.service';
import { GlobalService } from '../../shared/global.service';
import { FileServiceService } from '../../shared/services/file-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GstDetails } from 'src/app/models/gst-details';
import { CanComponentDeactivate } from '../../shared/guards/confirm-deactivate.service';
import { Observable } from 'rxjs';
import { RoleType } from 'src/app/enums/role-type.enum';
import { AlertService } from '../../shared/services/alert.service';
import { DataSharingService } from '../../shared/data-sharing.service';

@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.scss'],
})
export class AddVendorComponent implements OnInit, CanComponentDeactivate {

  avoidWarningPopUpMessageOnSubmit: boolean = false;

  maxFileSize: number = 3 * 1024 * 1024; // 3 MB limit
  fileSizeExceeds: boolean = false;
  aadharFileSizeExceeds: boolean = false;
  chequeFileSizeExceeds: boolean = false;

  IsPasswordChanged: any;
  userId: any;

  public loading = false;

  vendorForm: FormGroup = new FormGroup({});
  gstFormGroups = new FormArray<FormGroup>([]);

  isEditMode: boolean = false;
  enableSubmitBtn: boolean = false;
  vendorid: any;
  vendorData: any;

  //validate pancard
  listOfPancards: any;
  duplicatePancard: boolean = false;


  // Attachments
  panFile: any;
  aadharFile: any;
  chequeFile: any;

  // attachment url's


  // checking user role 
  loggedInUserRoleType: string | null = null;
  isSiteAdmin = RoleType.Site_Admin


  // attachments 
  panCardAttachmentJsonData: any;
  aadharCardAttachmentJsonData: any;
  cancelChequeAttachmentJsonData: any;

  viewpanCardAttachmentFileName: any;
  viewpanCardAttachmentDocument: any;

  viewaadharCardAttachmentFileName: any;
  viewaadharCardAttachmentDocument: any;

  viewcancelChequeAttachmentFileName: any;
  viewcancelChequeAttachmentDocument: any;




  // DOM elements
  @ViewChild('ackCheckbox') ackCheckbox!: ElementRef;

  states: { fullName: string, abbreviation: string }[] = [
    { fullName: "Andaman and Nicobar Islands", abbreviation: "AN" },
    { fullName: "Andhra Pradesh", abbreviation: "AP" },
    { fullName: "Arunachal Pradesh", abbreviation: "AR" },
    { fullName: "Assam", abbreviation: "AS" },
    { fullName: "Bihar", abbreviation: "BR" },
    { fullName: "Chandigarh", abbreviation: "CH" },
    { fullName: "Chhattisgarh", abbreviation: "CT" },
    { fullName: "Delhi", abbreviation: "DL" },
    { fullName: "Goa", abbreviation: "GA" },
    { fullName: "Gujarat", abbreviation: "GJ" },
    { fullName: "Haryana", abbreviation: "HR" },
    { fullName: "Himachal Pradesh", abbreviation: "HP" },
    { fullName: "Jammu and Kashmir", abbreviation: "JK" },
    { fullName: "Jharkhand", abbreviation: "JH" },
    { fullName: "Karnataka", abbreviation: "KA" },
    { fullName: "Kerala", abbreviation: "KL" },
    { fullName: "Ladakh", abbreviation: "LA" },
    { fullName: "Lakshadweep", abbreviation: "LD" },
    { fullName: "Madhya Pradesh", abbreviation: "MP" },
    { fullName: "Maharashtra", abbreviation: "MH" },
    { fullName: "Manipur", abbreviation: "MN" },
    { fullName: "Meghalaya", abbreviation: "ML" },
    { fullName: "Mizoram", abbreviation: "MZ" },
    { fullName: "Nagaland", abbreviation: "NL" },
    { fullName: "Odisha", abbreviation: "OD" },
    { fullName: "Puducherry", abbreviation: "PY" },
    { fullName: "Punjab", abbreviation: "PB" },
    { fullName: "Rajasthan", abbreviation: "RJ" },
    { fullName: "Sikkim", abbreviation: "SK" },
    { fullName: "Tamil Nadu", abbreviation: "TN" },
    { fullName: "Telangana", abbreviation: "TG" },
    { fullName: "Tripura", abbreviation: "TR" },
    { fullName: "Uttar Pradesh", abbreviation: "UP" },
    { fullName: "Uttarakhand", abbreviation: "UK" },
    { fullName: "West Bengal", abbreviation: "WB" }
  ];

  selectedState: string | null = null;

  bankNames: string[] = ['ICICI'];


  getStateAbbreviation(state: { fullName: string, abbreviation: string }): string {

    return state.abbreviation || ''; // Ensure that abbreviation is correctly retrieved
  }

  onStateSelected(event: any) {
    // Update selectedState when a state is selected
    this.selectedState = event.detail.value;
  }

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private businessService: BusinessService,
    private globalServices: GlobalService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private fileService: FileServiceService,
    private alertService: AlertService,
    private dataSharing: DataSharingService
  ) { }


  ngOnInit(): void {

    this.IsPasswordChanged = localStorage.getItem("IsPasswordChanged");
    this.userId = localStorage.getItem("UserId");

    if (this.IsPasswordChanged == 'False') {
 
      this.dataSharing.setChangePassword(false);
      this.dataSharing.setPopupVisibility(true);
    }
    this.loggedInUserRoleType = localStorage.getItem('RoleType');

    this.businessService.getAllPanCards().subscribe((data) => {
      this.listOfPancards = data;
    });

    this.vendorForm = this.formBuilder.group({
      businessName: ['', [Validators.required, this.globalServices.maxLengthValidator(50)]],
      businessPhone: ['', [Validators.required, this.globalServices.phoneNumberValidator(), this.globalServices.noSpacesValidator()]],
      businessEmail: ['', [this.globalServices.NotRequriedemailValidator(), this.globalServices.maxLengthValidator(50), this.globalServices.noSpacesValidator()]],
      businessAddress: ['', [Validators.required, this.globalServices.maxLengthValidator(255)]],
      businessState: ['', Validators.required],
      businessPincode: ['', [Validators.required, this.globalServices.pinCodeValidator(), this.globalServices.noSpacesValidator()]],
      businessPanCard: ['', [Validators.required, this.globalServices.panCardNumberValidator(), this.globalServices.noSpacesValidator()]],
      panCardAttachment: [''],
      firstName: ['', [Validators.required, this.globalServices.maxLengthValidator(30)]],
      lastName: ['', [this.globalServices.maxLengthValidator(30)]],
      designation: ['', [Validators.required, this.globalServices.maxLengthValidator(50)]],
      phoneNumber: ['', [Validators.required, this.globalServices.phoneNumberValidator(), this.globalServices.noSpacesValidator()]],
      emailId: ['', [this.globalServices.NotRequriedemailValidator(), this.globalServices.maxLengthValidator(50), this.globalServices.noSpacesValidator()]],
      aadharCard: ['', [this.globalServices.aadharValidator(), this.globalServices.noSpacesValidator()]],
      aadharCardAttachment: [''],
      beneficiaryName: ['', [Validators.required, this.globalServices.maxLengthValidator(100)]],
      accountNumber: ['', [Validators.required, this.globalServices.accountNumberValidator(), this.globalServices.noSpacesValidator()]],
      ifscCode: ['', [Validators.required, this.globalServices.noSpacesValidator(), this.globalServices.IfscCodeValidator()]],
      bankName: ['', [Validators.required]],
      otherBankName: [''],
      branchName: ['', [this.globalServices.maxLengthValidator(50)]],
      bankAddress: ['', [this.globalServices.maxLengthValidator(255)]],
      cancelChequeOrPassbook: [''],
      gst_details: this.gstFormGroups
    });

    this.addGstNumberField(); // Add at least one GST number field by default

    // Checking if vendor code in present in url for edit functinality
    this.vendorid = this.activateRoute.snapshot.paramMap.get("vendorid");
    if (this.vendorid) {
      // check role type
      if (this.loggedInUserRoleType == RoleType.Site_Admin) {
        this.alertService.accessDenied();
      }
      else {
        this.isEditMode = true;
        this.businessService.getVendor(this.vendorid).subscribe((data) => {
          this.vendorData = data[0]

          this.patchVendorFormData(data);

          // if (this.vendorData.Status == "1") {
          //   this.vendorForm.get('businessPanCard')?.disable();
          // }
        })
      }
    }

    // Set validator for cheque attachement if edit
    // if (!this.isEditMode) {
    //   this.vendorForm.get('cancelChequeOrPassbook')?.setValidators(null);
    //   this.vendorForm.get('cancelChequeOrPassbook')?.updateValueAndValidity();
    // } else {
    //   this.vendorForm.get('cancelChequeOrPassbook')?.setValidators(null);
    //   this.vendorForm.get('cancelChequeOrPassbook')?.updateValueAndValidity();
    // }

    // Enabling acknowlement id form is valid
    this.vendorForm.valueChanges.subscribe(() => {
      if (this.vendorForm.valid) {
        this.ackCheckbox.nativeElement.disabled = false;
      } else {
        this.ackCheckbox.nativeElement.disabled = true;
        this.ackCheckbox.nativeElement.checked = false;
        this.enableSubmitBtn = false;
      }

      if (this.vendorForm.get('bankName')?.value == 'others') {
        this.vendorForm.get('otherBankName')?.setValidators([Validators.required]);
        this.vendorForm.get('otherBankName')?.updateValueAndValidity();
      } else {
        this.vendorForm.get('otherBankName')?.setValidators(null);
        this.vendorForm.get('otherBankName')?.updateValueAndValidity();
      }
    });

    // Auto-fill beneficiaryName with businessName
    this.vendorForm.get('businessName')?.valueChanges.subscribe(value => {
      this.vendorForm.get('beneficiaryName')?.setValue(value);
    });


  }

  validatePancard() {
    const newPanCard = this.vendorForm.get('businessPanCard')?.value.trim().toUpperCase(); // Trim whitespace from the input and convert to uppercase


    if (!newPanCard) {
      // If newPanCard is empty or whitespace, no need to proceed with validation
      return;
    }

    // Extract the array of PAN cards from listOfPancards
    const panCards = this.listOfPancards?.PanCards ?? [];

    if (this.vendorid != null) {
      const panCardExists = panCards.includes(newPanCard);
      if (panCardExists) {
        if (newPanCard != this.vendorData.PanCard) {

          this.duplicatePancard = true;
          // Trigger validation re-evaluation in Angular form control
          this.vendorForm.get('businessPanCard')?.setValidators([Validators.required, this.ExistingPanCardValidator]);
          this.vendorForm.get('businessPanCard')?.updateValueAndValidity();

        }
        else {
          this.duplicatePancard = false;
          this.vendorForm.get('businessPanCard')?.setValidators([Validators.required, this.globalServices.panCardNumberValidator(), this.globalServices.noSpacesValidator()]);
          this.vendorForm.get('businessPanCard')?.updateValueAndValidity();
        }
      }
      else {
        this.duplicatePancard = false;
        this.vendorForm.get('businessPanCard')?.setValidators([Validators.required, this.globalServices.panCardNumberValidator(), this.globalServices.noSpacesValidator()]);
        this.vendorForm.get('businessPanCard')?.updateValueAndValidity();
      }

    } else {
      // Check if the newPanCard already exists in the list of PAN cards
      const panCardExists = panCards.includes(newPanCard);

      if (panCardExists) {
        // PAN card already exists, handle validation error
        // For example, set an error on the form control
        this.duplicatePancard = true;
        // Trigger validation re-evaluation in Angular form control
        this.vendorForm.get('businessPanCard')?.setValidators([Validators.required, this.ExistingPanCardValidator]);
        this.vendorForm.get('businessPanCard')?.updateValueAndValidity();
      } else {
        // PAN card is valid, clear any existing validation errors
        this.duplicatePancard = false;
        this.vendorForm.get('businessPanCard')?.setValidators([Validators.required, this.globalServices.panCardNumberValidator(), this.globalServices.noSpacesValidator()]);
        this.vendorForm.get('businessPanCard')?.updateValueAndValidity();
      }

    }

  }

  checkValidation() {
    Object.values(this.vendorForm.controls).forEach(control => {
      control.markAsTouched();
    });
  }

  ExistingPanCardValidator = (control: AbstractControl): { [key: string]: any } | null => {
    const pancard = control.root.get('businessPanCard')?.value;

    return { 'ExisitingPanCard': this.duplicatePancard };
  };


  ngAfterViewInit() {
    this.ackCheckbox.nativeElement.disabled = true;
  }

  resetForm() {
    this.vendorForm.reset();
    this.gstFormGroups = new FormArray<FormGroup>([]);
    this.ngOnInit()
  }

  canDeactivate(): Promise<boolean> | boolean {

    if (this.avoidWarningPopUpMessageOnSubmit) {
      return true;
    }
    else {
      if (this.vendorForm.dirty) {
        let flag = this.globalServices.confirmAction('You have unsaved changes! Are you sure you want to leave?')
        return flag;
      }
      return true;
    }
  }

  // Patching form data for edit functinality
  patchVendorFormData(vendorDetails: any) {
    vendorDetails = vendorDetails[0]

    // Check if the bank name is in the dropdown options
    if (!this.bankNames.includes(vendorDetails.BankName)) {
      // Add the bank name to the dropdown options
      this.bankNames.push(vendorDetails.BankName);
    }


    // Attachment start //
    const pancardAttachmentFileData = vendorDetails.PancardAttachment ? JSON.parse(vendorDetails.PancardAttachment) : null;


    if (pancardAttachmentFileData != null) {
      this.viewpanCardAttachmentFileName = pancardAttachmentFileData.fileName;

      const pancardfileType = pancardAttachmentFileData.fileType;
      const panCardbyteArray = new Uint8Array(pancardAttachmentFileData.byteArray);

      // Create a Blob from the byte array
      const panCardblob = new Blob([panCardbyteArray], { type: pancardfileType });
      // Create a URL for the Blob
      this.viewpanCardAttachmentDocument = URL.createObjectURL(panCardblob);
    }



    const aadharCardAttachmentFileData = vendorDetails.AadharCardAttachment ? JSON.parse(vendorDetails.AadharCardAttachment) : null;

    if (aadharCardAttachmentFileData != null) {

      this.viewaadharCardAttachmentFileName = aadharCardAttachmentFileData.fileName;

      const aadharCardfileType = aadharCardAttachmentFileData.fileType;
      const aadharCardbyteArray = new Uint8Array(aadharCardAttachmentFileData.byteArray);

      // Create a Blob from the byte array
      const aadharCardblob = new Blob([aadharCardbyteArray], { type: aadharCardfileType });
      // Create a URL for the Blob
      this.viewaadharCardAttachmentDocument = URL.createObjectURL(aadharCardblob);
    }


    const CancelChequeAttachmentFileData = vendorDetails.CancelChequePassbook ? JSON.parse(vendorDetails.CancelChequePassbook) : null;

    if (CancelChequeAttachmentFileData != null) {

      this.viewcancelChequeAttachmentFileName = CancelChequeAttachmentFileData.fileName;

      const CancelChequefileType = CancelChequeAttachmentFileData.fileType;
      const CancelChequebyteArray = new Uint8Array(CancelChequeAttachmentFileData.byteArray);

      // Create a Blob from the byte array
      const CancelChequeblob = new Blob([CancelChequebyteArray], { type: CancelChequefileType });
      // Create a URL for the Blob
      this.viewcancelChequeAttachmentDocument = URL.createObjectURL(CancelChequeblob);

    }

    // Atttachment code end //

    this.vendorForm.patchValue({



      // patch all fields here
      businessName: vendorDetails.BusinessName,
      businessPhone: vendorDetails.BusinessMobile,
      businessEmail: vendorDetails.BusinessEmail,
      businessAddress: vendorDetails.BusinessAddr,
      businessState: vendorDetails.State,
      businessPincode: vendorDetails.Pincode,
      businessPanCard: vendorDetails.PanCard,
      status: vendorDetails.Status,
      firstName: vendorDetails.FirstName,
      lastName: vendorDetails.LastName,
      phoneNumber: vendorDetails.Phone,
      emailId: vendorDetails.Email,
      designation: vendorDetails.Designation,
      aadharCard: vendorDetails.AadharCardNumber,
      beneficiaryName: vendorDetails.AccountHolderName,
      accountNumber: vendorDetails.AccountNumber,
      ifscCode: vendorDetails.IfscCode,
      bankName: vendorDetails.BankName,
      branchName: vendorDetails.Branch,
      bankAddress: vendorDetails.BankAddress,
    });

    // Clear existing form array before setting new values
    while (this.gstFormGroups.length !== 0) {
      this.gstFormGroups.removeAt(0);
    }

    if (vendorDetails && vendorDetails.GstDetails) {
      vendorDetails.GstDetails.forEach((gst: GstDetails) => {
        const gstFormGroup = this.formBuilder.group({
          gstId: [gst.GstId],
          gstNumber: [gst.GstNumber, [this.globalServices.gstNumberValidator()]],
          gstState: [gst.GstState]
        });
        this.gstFormGroups.push(gstFormGroup);
      });
    }
  }


  // Adding and deleteing GST numbers when respective buttons are triggered
  addGstNumberField() {
    const gstFormGroup = this.formBuilder.group({
      gstId: [''],
      gstNumber: ['', [this.gstNumberValidator(), this.globalServices.noSpacesValidator()]], // Use the custom validator here
      gstState: [''],
    });
    this.gstFormGroups.push(gstFormGroup);
  }

  gstNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const gstNumber = control.value.trim();
      // Check if gstNumber length is not 15 or if it's not valid, then return an error
      if (gstNumber && gstNumber.length !== 15) {
        return { 'invalidGstNumberLength': true };
      }
      return null; // Return null if the validation passes
    };
  }

  deleteGstDetails(index: number) {
    this.gstFormGroups.removeAt(index); // Remove the form group at the specified index

  }

  // Enabling submit button when acknowlement id checked
  onChangeAcknowledgement() {
    if (!this.ackCheckbox.nativeElement.disabled && this.ackCheckbox.nativeElement.checked) {
      this.enableSubmitBtn = true;
    } else {
      this.enableSubmitBtn = false;
    }
  }
  // Submitting the form

  onSubmit() {
    if (this.vendorForm.valid) {
      this.avoidWarningPopUpMessageOnSubmit = true;
      this.loading = true;
      this.serializeFormData(this.vendorForm.value).then(formData => {

        if (!this.isEditMode) {

          this.businessService.createVendor(formData).subscribe((response) => {
            if (response && response.vendorCode) {
              this.loading = false;

              // Display vendorCode as a popup message
              this.globalServices.customAlert("Vendor Successfully Created", `Vendor Code: ${response.vendorCode}`);
              this.router.navigateByUrl('allvendors');

            } else {
              console.log('Vendor code not found in the response.');
            }
          })
        } else {

          this.businessService.updateVendorDetails(formData, this.vendorid).subscribe((response) => {
            if (response) {
              this.loading = false;
              this.globalServices.customAlert("Success", "Vendor details updated");
              this.router.navigateByUrl(`vendor/${this.vendorid}`);

            }
          })
        }

      }).catch(error => {
        console.error('Error serializing form data:', error);
      });
    } else {
      this.avoidWarningPopUpMessageOnSubmit = false;
      console.log('Form invalid');
    }
  }

  // Converting the form into Requrired structure to process in Backend
  private async serializeFormData(formData: any): Promise<any> {
    const serializedData = {
      VendorCode: this.vendorid || '',
      BusinessName: formData.businessName.trim(),
      BusinessMobile: formData.businessPhone.trim(),
      BusinessEmail: formData.businessEmail.trim(),
      BusinessAddr: formData.businessAddress.trim(),
      State: formData.businessState.trim(),
      Pincode: formData.businessPincode,
      PanCard: formData.businessPanCard.trim() || this.vendorData.PanCard.trim(),
      PancardAttachment: this.panCardAttachmentJsonData || '',
      GstDetails: formData.gst_details.map((group: any) => ({
        GstId: group.gstId,
        GstNumber: group.gstNumber.trim(),
        GstState: group.gstState.trim()
      })),

      Status: this.loggedInUserRoleType == this.isSiteAdmin ? 0 : 1,
      FirstName: formData.firstName.trim(),
      LastName: formData.lastName.trim() || '',
      Phone: formData.phoneNumber,
      Email: formData.emailId.trim() || '',
      Designation: formData.designation.trim() || '',
      AadharCardNumber: formData.aadharCard.trim() || '',
      AadharCardAttachment: this.aadharCardAttachmentJsonData || '',
      AccountHolderName: formData.beneficiaryName.trim() || '',
      AccountNumber: formData.accountNumber.trim() || '',
      IfscCode: formData.ifscCode.trim() || '',
      BankName: formData.bankName === 'others' ? formData.otherBankName.trim() : formData.bankName.trim() || '',
      Branch: formData.branchName.trim() || '',
      BankAddress: formData.bankAddress || '',
      CancelChequePassbook: this.cancelChequeAttachmentJsonData || ''
    };

    return serializedData;
  }

  // Files related code 

  onFileSelected(event: any, controlName: string) {
    const file: File = event.target.files[0];

    if (file) {
      if (file.size > this.maxFileSize) {
        // Display error message or handle file size exceeding the limit
        alert('File size exceeds the limit of 3MB');
        this.vendorForm.get(controlName)?.setValue('');

        if (controlName == 'panCardAttachment') {
          this.fileSizeExceeds = true;
        }
        else if (controlName == 'aadharCardAttachment') {
          this.aadharFileSizeExceeds = true;
        } else {
          this.chequeFileSizeExceeds = true;
        }
        return;
      }
      this.fileSizeExceeds = false;
      this.aadharFileSizeExceeds = false;
      this.chequeFileSizeExceeds = false;
      // Additional logic to handle the file as byte array
      this.readFileAsByteArray(file).then(byteArray => {
        if (controlName == 'panCardAttachment') {
          this.panFile = byteArray;
          // Create a JavaScript object with the file details
          const fileData = {
            fileName: file.name,
            fileType: file.type,
            byteArray: Array.from(this.panFile)
          };
          this.panCardAttachmentJsonData = JSON.stringify(fileData);

        } else if (controlName == 'aadharCardAttachment') {
          this.aadharFile = byteArray;
          const fileData = {
            fileName: file.name,
            fileType: file.type,
            byteArray: Array.from(this.aadharFile)
          };
          this.aadharCardAttachmentJsonData = JSON.stringify(fileData);

        } else {
          this.chequeFile = byteArray;
          const fileData = {
            fileName: file.name,
            fileType: file.type,
            byteArray: Array.from(this.chequeFile)
          };
          this.cancelChequeAttachmentJsonData = JSON.stringify(fileData);

        }
      }).catch(error => {
        console.error('Error reading file:', error);
      });
    }
  }


  private async readFileAsByteArray(file: File): Promise<Uint8Array | null> {
    return new Promise<Uint8Array | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const uintArray = new Uint8Array(arrayBuffer);
        resolve(uintArray);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsArrayBuffer(file);
    });
  }


  deleteFile(event: any, controlName: string) {
    if (controlName == 'panCardAttachment') {
      this.panFile = undefined;
      this.vendorForm.get('panCardAttachment')?.setValue("");
    } else if (controlName == 'aadharCardAttachment') {
      this.aadharFile = undefined;
      this.vendorForm.get('aadharCardAttachment')?.setValue("");
    } else if (controlName == 'cancelChequeOrPassbook') {
      this.chequeFile = undefined;
      this.vendorForm.get('cancelChequeOrPassbook')?.setValue("");
    }






    // Reset the value of the file input
    // Find the input element by its formControlName
    this.vendorForm.get(controlName)?.setValue('');
    const inputElement = document.querySelector(`input[formControlName="${controlName}"]`);

    if (inputElement) {
      inputElement.classList.remove('uploaded-file');
    }
  }


  // Prompts an stranded confirmation message during reload or closeing tab

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.vendorData.dirty) {
      $event.returnValue = true; // Triggers the confirmation dialog asking if the user wants to leave the page
    }
  }

}