import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../modules/shared/services/login.service';
import { Router } from '@angular/router';
import { jwtDecode } from "jwt-decode";
import { GlobalService } from '../modules/shared/global.service';
import { BusinessService } from '../business.service';
import { DataSharingService } from '../modules/shared/data-sharing.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  invalidLogin: boolean = false;
  loginForm!: FormGroup;

  password: boolean = false;

  IsPasswordChanged: any;
  isPopupVisible: boolean = false;


  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private globalServices: GlobalService,
    private dataSharing: DataSharingService) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: [null, [Validators.required]],
      secretkey: [null, [Validators.required]]
    });
  }

  validateLogin() {
    if (this.loginForm.valid) {
      this.loginService.loginRequest(this.loginForm.value).subscribe((data: any) => {

        if (data.accessToken == "Invalid") {
          console.log("invalid login details");
          this.invalidLogin = true;
        }
        else {

          this.invalidLogin = false;
          console.log('Token:', `${data.accessToken}`);
          localStorage.setItem("Authorization", `${data.accessToken}`);
          let token: any = jwtDecode(data.accessToken);
          localStorage.setItem("Email", `${token.Email}`);
          localStorage.setItem("UserId", `${token.UserId}`);
          localStorage.setItem("RoleType", `${token.RoleType}`);
          localStorage.setItem("FirstName", `${token.FirstName}`);
          localStorage.setItem("LastName", `${token.LastName}`);
          localStorage.setItem("Password", `${token.Password}`);
          localStorage.setItem("IsPasswordChanged", `${token.IsPasswordChanged}`);

          this.router.navigate(['/dashboard']);
        }
      });
    }


  }

  togglePasswordVisibility() {
    this.password = !this.password;
  }


  forgotPassword() {
    this.globalServices.customAlert("Information", "Please contact your administrator for password change assistance");
  }
}
