import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BusinessService } from 'src/app/business.service';
import { GlobalService } from '../global.service';
import { DataSharingService } from '../data-sharing.service';
import { AppModule } from 'src/app/app.module';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {


  flag: boolean = false;
  oldPasswordValue: any;
  email: any;
  IsValidPassword: boolean = false;
  IsNotValidPassword: boolean = false;

  changePassword: any;
  passwordForm!: FormGroup;
  public loading = false;
  isPopupVisible: boolean = false;
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  confirmMessage = 'Are you sure you want change the password ?';


  differentUserPasswordChange: any;
  differentUserPasswordChangeUserId: any;



  userId: any;
  oldPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';

  constructor(private router: Router, private businessService: BusinessService,
    private globalServices: GlobalService, private dataSharing: DataSharingService,
    private fb: FormBuilder) { }

  ngOnInit() {

    console.log(this.changePassword);

    this.userId = localStorage.getItem("UserId");
    this.email = localStorage.getItem("Email")
    this.passwordForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, this.globalServices.passwordValidator()]],
      confirmPassword: ['', [Validators.required, this.globalServices.passwordValidator()]]
    }, { validators: this.passwordsMatchValidator() });


    this.dataSharing.setPassword(localStorage.getItem("Password") || '');



    this.dataSharing.isPopupVisible$.subscribe(isVisible => {
      this.isPopupVisible = isVisible;
    });

    this.dataSharing._changePassword$.subscribe(changePassword => {
      this.changePassword = changePassword;
    });

    this.dataSharing._isDifferentUserPasswordChange$.subscribe(differentUser => {
      this.differentUserPasswordChange = differentUser;
      console.log("different user login ", this.differentUserPasswordChange);

      if (this.differentUserPasswordChange) {
        this.IsValidPassword = true;
        this.passwordForm.get('oldPassword')?.clearValidators();
      } else {
        this.passwordForm.get('oldPassword')?.setValidators([Validators.required]);
      }
      this.passwordForm.get('oldPassword')?.updateValueAndValidity();

    });

    this.dataSharing.differentUserPasswordChangeUserId$.subscribe(userId => {
      this.differentUserPasswordChangeUserId = userId;
    })


  }


  passwordsMatchValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const newPassword = control.get('newPassword')?.value;
      const confirmPassword = control.get('confirmPassword')?.value;
  
      if (newPassword && confirmPassword && newPassword !== confirmPassword) {
        return { 'passwordsMismatch': true };
      }
      return null;
    };
  }



  validateOldPasssword() {
    // this.oldPasswordValue = this.dataSharing.getPassword();

    // const storedHashBytes = Uint8Array.from(atob(this.oldPasswordValue), c => c.charCodeAt(0));

    // const encoder = new TextEncoder();
    // const enteredPasswordBytes = encoder.encode(this.oldPassword);

    // // Hash the entered password using the same hashing algorithm used by the backend
    // // For example, if it's SHA-256
    // crypto.subtle.digest("SHA-256", enteredPasswordBytes).then(hash => {
    //   const hashArray = new Uint8Array(hash);

    //   // Compare the hashes
    //   if (this.arraysEqual(hashArray, storedHashBytes)) {
    //     this.IsValidPassword = true;
    //     this.IsNotValidPassword = false;
    //     console.log("Password is valid");
    //   } else {
    //     this.IsValidPassword = false;
    //     this.IsNotValidPassword = true;
    //     console.log("Password is not valid");
    //   }
    // });


    this.businessService.validatePassword(this.oldPassword, this.email).subscribe((res) => {

      if (res.status) {
        this.IsValidPassword = true;
        this.IsNotValidPassword = false;
      }
      else {
        this.IsValidPassword = false;
        this.IsNotValidPassword = true;
      }

    });


  }

  arraysEqual(a: any, b: any) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  // show/ hide password functionality


  toggleOldPasswordVisibility() {
    this.showOldPassword = !this.showOldPassword;
  }

  toggleNewPasswordVisibility() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }


  passwordsMatch(): boolean {

    if (this.IsValidPassword == true) {
      const newPasswordControl = this.passwordForm.get('newPassword');
      const confirmPasswordControl = this.passwordForm.get('confirmPassword');

      // Check if both controls are valid and have the same value
      return newPasswordControl!.valid && confirmPasswordControl!.valid && newPasswordControl!.value === confirmPasswordControl!.value;
    }
    else {
      return false;
    }
  }

  
  


  onCancel() {
    this.passwordForm.get('oldPassword')?.setValue("");
    this.passwordForm.get('newPassword')?.setValue("");
    this.passwordForm.get('confirmPassword')?.setValue("");
    this.dataSharing.setPopupVisibility(false);
  }

  async onSubmit() {

    if (this.changePassword) {
      this.flag = await this.globalServices.confirmAction(`Are you sure you want to change the password ? `);
    }
    else {
      this.flag = true;
    }




    if (this.flag) {
      if (this.differentUserPasswordChangeUserId) {
        this.userId = this.differentUserPasswordChangeUserId;
      }
      this.loading = true;
      const requestData = {
        Password: this.newPassword,

        UserId: this.userId,
      };

      this.businessService.createUser(requestData).subscribe(response => {
        if (response) {
          this.loading = false;
          this.dataSharing.setPopupVisibility(false);

          if (this.differentUserPasswordChange) {
            this.globalServices.customAlert("Success", `Password has been successfully updated`);
            this.router.navigateByUrl('/allusers');
            this.differentUserPasswordChange = false;
            
          }
          else {
            this.globalServices.customAlert("Success", `Password has been successfully updated. Please proceed to log in.`);
            this.router.navigateByUrl('/login');
          }

        } else {
          console.log('Error.');
        }

      },
        error => {
          this.loading = false;
          console.error('Error while updating the password:', error);
          this.globalServices.errorAlert("Error", `Unable to update password`);
        })
    }



  }


}
