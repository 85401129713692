import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from './change-password.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [ ChangePasswordComponent],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule, // Add FormsModule to the imports array
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports : [ChangePasswordComponent]
})
export class ChangePasswordModule { }
