import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {NgxPrintModule} from 'ngx-print';
import { NgxLoadingModule} from "ngx-loading";

// Components
import { AddVendorComponent } from './modules/vendors/add-vendor/add-vendor.component';
import { AllVendorsComponent } from './modules/vendors/all-vendors/all-vendors.component';
import { VendorDetailsComponent } from './modules/vendors/vendor-details/vendor-details.component';
import { AddPurchaseOrderComponent } from './modules/purchase-order/add-purchase-order/add-purchase-order.component';
import { LoginComponent } from './login/login.component';
import { AllPurchaseOrdersComponent } from './modules/purchase-order/all-purchase-orders/all-purchase-orders.component';
import { AddWorkOrderComponent } from './modules/work-order/add-work-order/add-work-order.component';
import { AllWorkOrderComponent } from './modules/work-order/all-work-order/all-work-order.component';
import { PurchaseOrderDetailsComponent } from './modules/purchase-order/purchase-order-details/purchase-order-details.component';
import { WorkOrderDetailsComponent } from './modules/work-order/work-order-details/work-order-details.component';
import { AddPaymentComponent } from './modules/payment/add-payment/add-payment.component';
import { AllPaymentsComponent } from './modules/payment/all-payments/all-payments.component';
import { ConfirmResetDirective } from './directives/confirm-reset.directive';
import { AddUserComponent } from './modules/user/add-user/add-user.component';
import { AllUsersComponent } from './modules/user/all-users/all-users.component';
import { AccessTokenInterceptorService } from './modules/shared/interceptors/access-token-interceptor.service';
import { PaymentDetailsComponent } from './modules/payment/payment-details/payment-details.component';
import { UserDetailsComponent } from './modules/user/user-details/user-details.component';
import { PaginationComponent } from './modules/pagination/pagination.component';
import { ChangePasswordModule } from './modules/shared/change-password/change-password.module';
import { registerLocaleData } from '@angular/common';
import localeIn from '@angular/common/locales/en-IN';

registerLocaleData(localeIn, 'en-IN');

@NgModule({
  declarations: [
    LoginComponent,
    AppComponent,
    AddVendorComponent,
    AllVendorsComponent,
    VendorDetailsComponent,
    AddWorkOrderComponent,
    AllWorkOrderComponent,
    AddPurchaseOrderComponent,
    AllPurchaseOrdersComponent,
    PurchaseOrderDetailsComponent,
    WorkOrderDetailsComponent,
    AddPaymentComponent,
    AllPaymentsComponent,
    PaymentDetailsComponent,
    ConfirmResetDirective,
    AddUserComponent,
    AllUsersComponent,
    UserDetailsComponent,
    PaginationComponent
  ],

  imports: [
    BrowserModule,
    NgxPrintModule,
    IonicModule.forRoot(),
    NgxLoadingModule.forRoot({}),
    AppRoutingModule, HttpClientModule,
    FormsModule, ReactiveFormsModule, 
    ChangePasswordModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
