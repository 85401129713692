  <div class="popup-main-container"  *ngIf="isPopupVisible">
        <div class="card card-outline-secondary">
            <div class="card-header" style="background-color: #09407e;color: white;">
                <h3 class="mb-0">Change Password</h3>
            </div>
            <div class="card-body">
                <form [formGroup]="passwordForm" class="form" role="form" autocomplete="off" style="width: 500px;">
                    <div *ngIf="!differentUserPasswordChange">
                    <div class="form-group" >
                        <label for="inputPasswordOld" style="margin-bottom: 5px;" class="required">Old Password</label>
                        <input type="{{ showOldPassword ? 'text' : 'password' }}" formControlName="oldPassword" class="form-control"
                            [(ngModel)]="oldPassword" (blur)="validateOldPasssword()">
                            <div style="position: relative;">
                        <ion-icon class="eye-icon" name="{{ showOldPassword ? 'eye-outline' : 'eye-off-outline' }}"
                            (click)="toggleOldPasswordVisibility()"></ion-icon>
                        </div>
                    </div>
                    
                    <p class="errorMessage"
                    *ngIf="passwordForm.get('oldPassword')?.errors?.['required'] && passwordForm.get('oldPassword')?.touched">This field is
                    required</p>
                    <p class="successMessage"
                    *ngIf="IsValidPassword">Valid</p>
                    <p class="errorMessage"
                    *ngIf="IsNotValidPassword">Invalid password</p>
                </div>
                    

                    <div class="form-group" style="margin-top: 10px;">
                        <label for="inputPasswordNew" style="margin-bottom: 5px;" class="required">New Password</label>
                        <input type="{{ showNewPassword ? 'text' : 'password' }}" formControlName="newPassword" class="form-control" required
                            [(ngModel)]="newPassword">
                            <div style="position: relative;">
                        <ion-icon class="eye-icon" name="{{ showNewPassword ? 'eye-outline' : 'eye-off-outline' }}"
                            (click)="toggleNewPasswordVisibility()"></ion-icon>
                        </div>
                    </div>
                    <p class="errorMessage"
                    *ngIf="passwordForm.get('newPassword')?.errors?.['required'] && passwordForm.get('newPassword')?.touched">This field is
                    required</p>
                  <p class="errorMessage"
                    *ngIf="passwordForm.get('newPassword')?.errors?.['invalidPassword'] && passwordForm.get('newPassword')?.touched && !passwordForm.get('newPassword')?.errors?.['required']">
                    Password must be at least 8 characters long, containing at least one uppercase letter, one lowercase
                    letter, one digit, and one special character.</p>

                    <div class="form-group" style="margin-top: 10px;">
                        <label for="inputPasswordConfirm" style="margin-bottom: 5px;">Confirm Password</label>
                        <input formControlName="confirmPassword" type="{{ showConfirmPassword ? 'text' : 'password' }}" class="form-control" id="inputPasswordNew" required
                            [(ngModel)]="confirmPassword">
                        <div style="position: relative;">
                            <ion-icon class="eye-icon" name="{{ showConfirmPassword ? 'eye-outline' : 'eye-off-outline' }}"
                            (click)="toggleConfirmPasswordVisibility()"></ion-icon>
                        </div>
                    </div>
                    <p class="errorMessage"
                        *ngIf="passwordForm.get('confirmPassword')?.errors?.['required'] && passwordForm.get('confirmPassword')?.touched">This field is
                        required</p>
                        <p class="errorMessage"
                    *ngIf="passwordForm.get('confirmPassword')?.errors?.['invalidPassword'] && passwordForm.get('confirmPassword')?.touched && !passwordForm.get('confirmPassword')?.errors?.['required']">
                    Password must be at least 8 characters long, containing at least one uppercase letter, one lowercase
                    letter, one digit, and one special character.</p>
                    <p class="errorMessage"></p>
                    <!-- <span class="errorMessage" *ngIf="!passwordsMatch()">New and Confirm password are not match</span> -->
                    <p class="errorMessage" *ngIf="passwordForm.errors?.['passwordsMismatch'] && passwordForm.get('confirmPassword')?.touched">
                        Passwords do not match.
                      </p>
                    
                    <div class="form-group d-flex" style="margin-top: 10px; float: right;">
                       
                        <button type="submit" class="btn btn-danger" style="float: right;" [hidden]="!changePassword" (click)="onCancel()">Cancel</button>&nbsp;&nbsp;&nbsp;
                        <button class="btn btn-success" style="float: right;" [disabled]="!passwordsMatch()" (click)="onSubmit()">Submit</button>
                        
                        
                    </div>
                </form>
                
            </div>
        </div>
    </div>