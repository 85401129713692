<div class="add-vendor-main-container altering-container" id="addVendorContainer">

  <div class="title-container p-3 bg-darkgray-color">

    <div>
      <button *ngIf="isEditMode" class="btn btn-primary" [routerLink]="['/allvendors']">Back</button>
    </div>
    <div>
      <h3 class="title">{{ isEditMode ? 'Edit Vendor Details' : 'Add Vendor Details'}}</h3>
    </div>
    <div>

    </div>

  </div>
  <hr>
  <ngx-loading [show]="loading"
    [config]="{ backdropBorderRadius: '14px', primaryColour: '#0e4f98', secondaryColour: '#0e4f98', tertiaryColour: '#0e4f98', fullScreenBackdrop: true }"></ngx-loading>


  <form [formGroup]="vendorForm" (ngSubmit)="onSubmit()">

    <div class="title-container p-3">
      <h4 class="title ">Business Name</h4>
    </div>

    <div class="input-container required py-1 px-3">
      <label class="required">Business Name</label>
      <input formControlName="businessName" placeholder="Business Name">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('businessName')?.invalid && vendorForm.get('businessName')?.touched && vendorForm.get('businessName')?.errors?.['required']">
      This field is required</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('businessName')?.errors?.['maxLengthExceeded'] && vendorForm.get('businessName')?.touched && !vendorForm.get('businessName')?.errors?.['required']">
      Maximum character limit of 50 exceeded</p>

    <div class="input-container required  py-1 px-3">
      <label class="required">Business Phone Number</label>
      <input formControlName="businessPhone" placeholder="Eg: 9933454534">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('businessPhone')?.errors?.['required'] && vendorForm.get('businessPhone')?.touched">This
      field is required</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('businessPhone')?.errors?.['invalidPhoneNumber'] && vendorForm.get('businessPhone')?.touched && !vendorForm.get('businessPhone')?.errors?.['required']">
      Please enter a valid phone number with 8 to 13 digits</p>
      <p class="errorMessage"
      *ngIf="vendorForm.get('businessPhone')?.errors?.['noSpaces'] && vendorForm.get('businessPhone')?.touched && !vendorForm.get('businessPhone')?.errors?.['required']">
      Spaces are not allowed in this field</p>

    <div class="input-container required py-1 px-3">
      <label>Business Email ID</label>
      <input formControlName="businessEmail" placeholder="Eg: example@gmail.com">
    </div>

    <p class="errorMessage"
      *ngIf="vendorForm.get('businessEmail')?.errors?.['invalidEmailid'] && vendorForm.get('businessEmail')?.touched">
      Please enter a
      valid email address</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('businessEmail')?.errors?.['maxLengthExceeded'] && vendorForm.get('businessEmail')?.touched && !vendorForm.get('businessEmail')?.errors?.['required']">
      Maximum character limit of 50 exceeded</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('businessEmail')?.errors?.['noSpaces'] && vendorForm.get('businessEmail')?.touched && !vendorForm.get('businessEmail')?.errors?.['required']">
      Spaces are not allowed in this field</p>

    <div class="input-container required py-1 px-3">
      <label class="required">Business Address</label>
      <input formControlName="businessAddress" placeholder="Business Address">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('businessAddress')?.invalid && vendorForm.get('businessAddress')?.errors?.['required'] && vendorForm.get('businessAddress')?.touched">
      This field is required</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('businessAddress')?.errors?.['maxLengthExceeded'] && vendorForm.get('businessAddress')?.touched">
      Maximum character limit of 255 exceeded</p>


    <div class="input-container required py-1 px-3">
      <label class="required">State & Pin Code</label>
      <div class="d-flex justify-content-between two-inputs">
        <select formControlName="businessState" class="w-45">
          <option value="" hidden selected>State</option>
          <option *ngFor="let state of states" [value]="state.fullName">
            {{ state.fullName }}
          </option>
        </select>
        <input formControlName="businessPincode" type="text" placeholder="Pin Code" class="w-45">
      </div>
    </div>

  
    <div class="d-flex justify-content-end input-container">
      <div class="d-flex justify-content-between two-inputs">
        <div>
          <p style="width: fit-content !important;" class="errorMessage"
            *ngIf="this.vendorForm.get('businessState')?.invalid && this.vendorForm.get('businessState')?.touched">This
            field is required</p>
        </div>

        <div>
          <p style="width: fit-content !important;" class="errorMessage"
            *ngIf="vendorForm.get('businessPincode')?.errors?.['required'] && vendorForm.get('businessPincode')?.touched">
            This field is required</p>
          <p style="width: fit-content !important;" class="errorMessage"
            *ngIf="vendorForm.get('businessPincode')?.errors?.['invalidPinCode'] && vendorForm.get('businessPincode')?.touched && !vendorForm.get('businessPincode')?.errors?.['required']">
            Please enter a 6-digit Pin Code</p>
            <p style="width: fit-content !important;" class="errorMessage"
            *ngIf="vendorForm.get('businessPincode')?.errors?.['noSpaces'] && vendorForm.get('businessPincode')?.touched && !vendorForm.get('businessPincode')?.errors?.['required']">
            Spaces are not allowed in this field</p>
        </div>
      </div>
    </div>


    <div formArrayName="gst_details" class="position-relative input-container flex-column">
      <div *ngFor="let gstGroup of gstFormGroups.controls; let i = index" [formGroupName]="i" class="position-relative">
        <div class="input-container gstDetailsRequired py-1 px-3">
          <label>GST Number & State</label>
          <input formControlName="gstId" hidden />
          <div class="input-dropdown">
            <input formControlName="gstNumber" placeholder="Enter GST number">
            <select class="vendor-gst-details" formControlName="gstState"
              [disabled]="!gstFormGroups.controls[i].get('gstNumber')?.valid" (ionChange)="onStateSelected($event)">
              <option hidden value="" selected>State</option>
              <option *ngFor="let state of states" [value]="state.fullName">
                {{ selectedState != null ? getStateAbbreviation(state) : state.fullName }}
              </option>
            </select>
          </div>
        </div>

        <button class="btn btn-danger delete-button" *ngIf="gstFormGroups.controls.length != 1"
          (click)="deleteGstDetails(i)" title="delete">
          <ion-icon name="trash-outline"></ion-icon>
        </button>
        <p class="errorMessage"
          *ngIf="gstFormGroups.controls[i].get('gstNumber')?.errors?.['invalidGstNumberLength'] && gstFormGroups.controls[i].get('gstNumber')?.touched">
          Please enter a valid GST number (15 characters).</p>
        <p class="errorMessage"
          *ngIf="gstFormGroups.controls[i].get('gstNumber')?.errors?.['noSpaces'] && gstFormGroups.controls[i].get('gstNumber')?.touched">
          Spaces are not allowed in this field
        </p>
      </div>


    </div>

    <div class="input-container justify-content-end py-1 px-3 align-items-end">
      <button id="addGstNumberBtn" class="btn btn-primary fs-6 py-0" type="button" (click)="addGstNumberField()">Add
        More</button>
    </div>


    <div class="input-container required py-1 px-3">
      <label class="required">PAN Number</label>
      <input formControlName="businessPanCard" placeholder="Eg: ABCTY1234D" (blur)="validatePancard()">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('businessPanCard')?.errors?.['required'] && vendorForm.get('businessPanCard')?.touched">
      This field is required
    </p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('businessPanCard')?.errors?.['invalidPanCard'] && vendorForm.get('businessPanCard')?.touched && !vendorForm.get('businessPanCard')?.errors?.['required']">
      Please enter a valid pancard number
    </p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('businessPanCard')?.errors?.['noSpaces'] && vendorForm.get('businessPanCard')?.touched && !vendorForm.get('businessPanCard')?.errors?.['required']">
      Spaces are not allowed in this field
    </p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('businessPanCard')?.errors?.['ExisitingPanCard'] && vendorForm.get('businessPanCard')?.touched">
      PAN Card already exists</p>



    <div class="input-container py-1 px-3">
      <label>PAN Card Attachment</label>
      <input formControlName="panCardAttachment" type="file" accept=".pdf, image/png, image/jpeg, image/jpg"
        placeholder="Pan card Attachment" (change)="onFileSelected($event , 'panCardAttachment')">
      <span class="filename-container" *ngIf="vendorForm.get('panCardAttachment')?.value">
        {{ vendorForm.get('panCardAttachment')?.value.name }}
        <ion-icon class="delete-icon" title="Delete" (click)="deleteFile($event, 'panCardAttachment')" name="close"
          color="danger"></ion-icon>
      </span>
      <a class="attachments clickable-file" *ngIf="viewpanCardAttachmentDocument" [href]="viewpanCardAttachmentDocument"
        target="_blank" rel="noopener noreferrer">{{viewpanCardAttachmentFileName}}</a>
    </div>



    <div class="input-container py-0 justify-content-end required px-3 text-right mb-3"
      style="margin-bottom: 0 !important;">
      <div>

      </div>
      <div class="two-inputs d-flex justify-content-between">
        <span class="text-secondary" style="font-size: 11px;">Supported File Types: pdf, png, jpg, and jpeg. Maximum
          file size: 3MB</span>
        <!-- <a  class="attachments clickable-file" *ngIf="PancardAttachmentURL" [href]="PancardAttachmentURL" target="_blank" rel="noopener noreferrer">Attached PAN Card</a> -->
      </div>
    </div>
    <div class="input-container py-0 justify-content-end required px-3 text-right mb-3">

      <div class="two-inputs d-flex justify-content-between">
        <span *ngIf="fileSizeExceeds" style="font-size: 11px;color: red;">File size exceeds the limit ({{ maxFileSize /
          (1024 * 1024) }} MB)</span>
      </div>

    </div>
    <div class="title-container p-3">
      <h4 class="title ">Contact Person Details</h4>
    </div>

    <div class="input-container required py-1 px-3">
      <label class="required">First Name</label>
      <input formControlName="firstName" placeholder="First Name">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('firstName')?.errors?.['required'] && vendorForm.get('firstName')?.touched">This field is
      required</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('firstName')?.errors?.['maxLengthExceeded'] && vendorForm.get('firstName')?.touched">Maximum
      character limit of 30 exceeded</p>


    <div class="input-container py-1 px-3">
      <label>Last Name</label>
      <input formControlName="lastName" placeholder="Last Name">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('lastName')?.errors?.['maxLengthExceeded'] && vendorForm.get('lastName')?.touched">Maximum
      character limit of 30 exceeded</p>



    <div class="input-container required py-1 px-3">
      <label class="required">Designation</label>
      <input formControlName="designation" placeholder="Designation">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('designation')?.errors?.['required'] && vendorForm.get('designation')?.touched">This field
      is required</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('designation')?.errors?.['maxLengthExceeded'] && vendorForm.get('designation')?.touched && !vendorForm.get('designation')?.errors?.['required']">
      Maximum character limit of 50 exceeded</p>


    <div class="input-container required py-1 px-3">
      <label class="required">Phone Number</label>
      <input formControlName="phoneNumber" placeholder="Phone Number">
    </div>
    <!-- Validation Message for Required -->
    <p class="errorMessage"
      *ngIf="vendorForm.get('phoneNumber')?.errors?.['required'] && vendorForm.get('phoneNumber')?.touched">This field
      is required</p>
    <!-- Validation Message for Invalid Phone Number Format -->
    <p class="errorMessage"
      *ngIf="vendorForm.get('phoneNumber')?.errors?.['invalidPhoneNumber'] && vendorForm.get('phoneNumber')?.touched && !vendorForm.get('phoneNumber')?.errors?.['required']">
      Please enter a valid phone number with 8 to 13 digits</p>
      <p class="errorMessage"
      *ngIf="vendorForm.get('phoneNumber')?.errors?.['noSpaces'] && vendorForm.get('phoneNumber')?.touched && !vendorForm.get('phoneNumber')?.errors?.['required']">
      Spaces are not allowed in this field</p>
    


    <div class="input-container py-1 px-3">
      <label>Email ID</label>
      <input formControlName="emailId" placeholder="Eg: example@gmail.com">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('emailId')?.errors?.['invalidEmailid'] && vendorForm.get('emailId')?.touched">Please enter a
      valid email address</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('emailId')?.errors?.['maxLengthExceeded'] && vendorForm.get('emailId')?.touched && !vendorForm.get('emailId')?.errors?.['required']">
      Maximum character limit of 50 exceeded</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('emailId')?.errors?.['noSpaces'] && vendorForm.get('emailId')?.touched && !vendorForm.get('emailId')?.errors?.['required']">
      Spaces are not allowed in this field</p>


    <div class="input-container py-1 px-3">
      <label>Aadhar Card Number</label>
      <input formControlName="aadharCard" placeholder="Eg: 234345564893">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('aadharCard')?.errors?.['invalidAadhar'] && vendorForm.get('aadharCard')?.touched">Please
      enter a 12-digit Aadhar Number</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('aadharCard')?.errors?.['noSpaces'] && vendorForm.get('aadharCard')?.touched && !vendorForm.get('aadharCard')?.errors?.['required']">
      Spaces are not allowed in this field</p>

    <div class="input-container py-1 px-3">
      <label>Aadhar Card Attachment</label>
      <input formControlName="aadharCardAttachment" type="file" accept=".pdf, image/png, image/jpeg, image/jpg"
        placeholder="Aadhar Card Attachment" (change)="onFileSelected($event , 'aadharCardAttachment')">
      <span class="filename-container" *ngIf="vendorForm.get('aadharCardAttachment')?.value">
        {{ vendorForm.get('aadharCardAttachment')?.value.name }}
        <ion-icon class="delete-icon" title="Delete" (click)="deleteFile($event, 'aadharCardAttachment')" name="close"
          color="danger"></ion-icon>
      </span>
      <a class="attachments clickable-file" *ngIf="viewaadharCardAttachmentDocument"
        [href]="viewaadharCardAttachmentDocument" target="_blank"
        rel="noopener noreferrer">{{viewaadharCardAttachmentFileName}}</a>
    </div>

    <div class="input-container py-0 justify-content-end required px-3 text-right mb-3"
      style="margin-bottom: 0 !important;">
      <div>

      </div>
      <div class="two-inputs d-flex justify-content-between">
        <span class="text-secondary" style="font-size: 11px;">Supported File Types: pdf, png, jpg, and jpeg. Maximum
          file size: 3MB</span>
        <!-- <a class="attachments clickable-file" *ngIf="AadharcardAttachment" [href]="AadharcardAttachment" target="_blank" rel="noopener noreferrer">Attached Aadharcard</a> -->
      </div>
    </div>
    <div class="input-container py-0 justify-content-end required px-3 text-right mb-3">

      <div class="two-inputs d-flex justify-content-between">
        <span *ngIf="aadharFileSizeExceeds" style="font-size: 11px;color: red;">File size exceeds the limit ({{
          maxFileSize / (1024 * 1024) }} MB)</span>
      </div>

    </div>

    <div class="title-container p-3">
      <h4 class="title ">Bank Details</h4>
    </div>

    <div class="input-container required py-1 px-3">
      <label class="required">Beneficiary Name</label>
      <input formControlName="beneficiaryName" placeholder="Beneficiary Name">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('beneficiaryName')?.errors?.['required'] && vendorForm.get('beneficiaryName')?.touched">This
      field is required</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('beneficiaryName')?.errors?.['maxLengthExceeded'] && vendorForm.get('beneficiaryName')?.touched">
      Maximum character limit of 100 exceeded</p>

    <div class="input-container required py-1 px-3">
      <label class="required">Account Number</label>
      <input formControlName="accountNumber" placeholder="Account Number">

    </div>
      <p class="errorMessage"
      *ngIf="vendorForm.get('accountNumber')?.errors?.['required'] && vendorForm.get('accountNumber')?.touched">
      This field is required
    </p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('accountNumber')?.errors?.['invalidAccountNumber'] && vendorForm.get('accountNumber')?.touched && !vendorForm.get('accountNumber')?.errors?.['required']">
      Please enter a valid account number
    </p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('accountNumber')?.errors?.['noSpaces'] && vendorForm.get('accountNumber')?.touched && !vendorForm.get('accountNumber')?.errors?.['required']">
      Spaces are not allowed in this field
    </p>



    <div class="input-container required py-1 px-3">
      <label class="required">Bank Name</label>


      <select formControlName="bankName">
        <option value="" hidden selected>Select Bank Name</option>
        <option *ngFor="let bank of bankNames" [value]="bank">{{bank}}</option>
        <option value="others">Others</option>
      </select>

      <!-- <input formControlName="bankName"  placeholder="Bank Name" required> -->
    </div>

    <p class="errorMessage"
      *ngIf="vendorForm.get('bankName')?.errors?.['required'] && vendorForm.get('bankName')?.touched">This field is
      required</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('bankName')?.errors?.['maxLengthExceeded'] && vendorForm.get('bankName')?.touched && !vendorForm.get('bankName')?.errors?.['required']">
      Maximum character limit of 50 exceeded</p>

    <div class="input-container required py-1 px-3" *ngIf="vendorForm.get('bankName')?.value == 'others'">
      <label class="required">Other Bank Name</label>
      <input placeholder="Other Bank Name" formControlName="otherBankName">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('bankName')?.value == 'others' && vendorForm.get('otherBankName')?.errors?.['required']">
      This field is required</p>
    <div class="input-container required py-1 px-3">
      <label class="required">IFSC Code</label>
      <input formControlName="ifscCode" placeholder="Eg: HDXX0005943">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('ifscCode')?.invalid && vendorForm.get('ifscCode')?.touched && vendorForm.get('ifscCode')?.errors?.['required']">
      This field is required</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('ifscCode')?.errors?.['noSpaces'] && vendorForm.get('ifscCode')?.touched && !vendorForm.get('ifscCode')?.errors?.['required']">
      Spaces are not allowed in this field</p>
    <p class="errorMessage"
      *ngIf="vendorForm.get('ifscCode')?.errors?.['invalidIFSCCode'] && vendorForm.get('ifscCode')?.touched && !vendorForm.get('ifscCode')?.errors?.['required']">
      Invalid IFSC Code</p>

    <div class="input-container py-1 px-3">
      <label>Branch Name</label>
      <input formControlName="branchName" placeholder="Branch Name">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('branchName')?.errors?.['maxLengthExceeded'] && vendorForm.get('branchName')?.touched && !vendorForm.get('branchName')?.errors?.['required']">
      Maximum character limit of 50 exceeded</p>


    <div class="input-container py-1 px-3">
      <label>Bank Address</label>
      <input formControlName="bankAddress" placeholder="Bank Address">
    </div>
    <p class="errorMessage"
      *ngIf="vendorForm.get('bankAddress')?.value && vendorForm.get('bankAddress')?.errors?.['maxLengthExceeded'] && vendorForm.get('bankAddress')?.touched">
      Maximum character limit of 255 exceeded</p>



    <div class="input-container required py-1 px-3">
      <label>Cancelled Cheque<br> or Passbook</label>
      <input formControlName="cancelChequeOrPassbook" type="file" accept=".pdf, image/png, image/jpeg, image/jpg"
        placeholder="Cancel Cheque Or Passbook" (change)="onFileSelected($event , 'cancelChequeOrPassbook')">
      <span class="filename-container" *ngIf="chequeFile">
        <ion-icon class="delete-icon" title="Delete" (click)="deleteFile($event, 'cancelChequeOrPassbook')" name="close"
          color="danger"></ion-icon>
      </span>
      <a class="attachments clickable-file" *ngIf="viewcancelChequeAttachmentDocument"
        [href]="viewcancelChequeAttachmentDocument" target="_blank"
        rel="noopener noreferrer">{{viewcancelChequeAttachmentFileName}}</a>

    </div>

    <div class="input-container py-0 justify-content-end required px-3 text-right mb-3"
      style="margin-bottom: 0 !important;">
      <div>

      </div>
      <div class="two-inputs d-flex justify-content-between">
        <span class="text-secondary" style="font-size: 11px;">Supported File Types: pdf, png, jpg, and jpeg. Maximum
          file size: 3MB</span>
      </div>
    </div>
    <div class="input-container py-0 justify-content-end required px-3 text-right mb-3">

      <div class="two-inputs d-flex justify-content-between">
        <span *ngIf="chequeFileSizeExceeds" style="font-size: 11px;color: red;">File size exceeds the limit ({{
          maxFileSize / (1024 * 1024) }} MB)</span>
      </div>

    </div>

    <p class="errorMessage"
      *ngIf="this.vendorForm.get('cancelChequeOrPassbook')?.invalid && this.vendorForm.get('cancelChequeOrPassbook')?.touched">
      This field is required</p>
    <hr>
    <div class="p-3" (click)="checkValidation()">
      <div>
        <label for="acknowleadgement" class="acknowleadgement"><input type="checkbox"
            class="acknowledgementCheckbox me-2" id="acknowleadgement" #ackCheckbox
            (change)="onChangeAcknowledgement()">I hereby acknowledge that I have read and confirmed the accuracy of the
          above form data. I understand that the information provided is true and complete to the best of my knowledge.
          By confirming this data, I accept any responsibility for its accuracy and agree to abide by the terms and
          conditions associated with the provided information.</label>
      </div>
    </div>

    <div class="p-3" *ngIf="isEditMode">
      <p class=""><b>Last Updated By</b> {{vendorData?.CreatedBy}}</p>
    </div>
    <div class="button-container p-2">
      <button class="btn btn-primary" type="submit" [disabled]="!enableSubmitBtn">{{ loggedInUserRoleType == isSiteAdmin
        ? 'Submit' : 'Submit & Approve' }}</button>
      <button class="unfilled-blue-btn py-2" type="reset" fill="outline" appConfirmReset
        (confirmReset)="resetForm()">Reset</button>
    </div>
  </form>
</div>

<app-change-password></app-change-password>