import { Directive, HostListener, EventEmitter, Output } from '@angular/core';
import { GlobalService } from '../modules/shared/global.service';

@Directive({
  selector: '[appConfirmReset]'
})
export class ConfirmResetDirective {

  @Output() confirmReset = new EventEmitter<void>();

  constructor(private globalService: GlobalService) { }

  @HostListener('click', ['$event'])
  async onClick(event: MouseEvent) {
    event.preventDefault();
    const confirmMessage = 'Are you sure you want to reset?';
    const confirmed = await this.globalService.confirmAction(confirmMessage);
    
    if (confirmed) {
      this.confirmReset.emit();
    }
  }
}
