import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { VendorDetails } from './models/vendor-details';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {
  private vendorsapiUrl: string = environment.vendorsapiUrl + '/business';
  private workordersapiUrl: string = environment.workordersapiUrl + '/api/business';
 
  
  private purchaseapiUrl: string = environment.purchaseapiUrl + '/api/business';
  private paymentsapiUrl: string = environment.paymentsapiUrl + '/api/business';
  private usersapiUrl: string = environment.usersapiUrl + '/api/business';


  constructor(private http: HttpClient) { }

    /////////////////////////
  // vendor Services
  /////////////////////////
  
  // add a vendor
  createVendor(vendorData: any): Observable<any> {
    return this.http.post<any>(`${this.vendorsapiUrl}/vendor`, vendorData);
  }

  //get all vendors
  getAllVendors(): Observable<any> {
    return this.http.get(`${this.vendorsapiUrl}/vendors`);
  }

  filterVendorsBySearch(searchQuery : any, currentPage:number, pageSize : number): Observable<any>{
      return this.http.get(`${this.vendorsapiUrl}/vendors/filterbysearch?search=${searchQuery}&page=${currentPage}&pageSize=${pageSize}`);
  }

  filterVendorByStatus(statusValue: string, currentPage:number, pageSize : number): Observable<any> {
    // Make the HTTP PUT request with the headers
    return this.http.get<any>(`${this.vendorsapiUrl}/filterbystatus/${statusValue}?page=${currentPage}&pageSize=${pageSize}`);
  }




  //get all vendors orders
  getVendors(currentPage:number, pageSize : number): Observable<any> {
    const url = `${this.vendorsapiUrl}/allvendors?page=${currentPage}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
    //return this.http.get(`${this.workordersapiUrl}/workorders`);
  }


  validatePassword(oldPassword:string, email : string): Observable<any> {
    const url = `${this.vendorsapiUrl}/validatepassword?oldpassword=${oldPassword}&email=${email}`;
    return this.http.get<any>(url);
    //return this.http.get(`${this.workordersapiUrl}/workorders`);
  }



  // Update vendor status
  updateVendorStatus(vendorCode: string, newStatus: number): Observable<any> {
    // Make the HTTP PUT request with the headers
    return this.http.put<any>(`${this.vendorsapiUrl}/updatestatus/${vendorCode}?status=${newStatus}`,{});
  }

  deleteVendor(vendorCode: string) : Observable<any> {
    return this.http.delete<any>(`${this.vendorsapiUrl}/vendor/${vendorCode}`);
  }

  updateVendorDetails(vendorData: any, vendorCode: string): Observable<any> {
    // Pass the headers as the third parameter
    return this.http.put<any>(`${this.vendorsapiUrl}/vendor/${vendorCode}`,vendorData);
  }

  getVendor(vendorCode: string): Observable<any> {
    return this.http.get(`${this.vendorsapiUrl}/vendor/${vendorCode}`);
  }

  getAllPanCards(): Observable<any> {
    return this.http.get(`${this.vendorsapiUrl}/vendors/allPancards`);
  }

  getOrdersOfVendor(vendorCode:string): Observable<any>{
    return this.http.get(`${this.vendorsapiUrl}/vendor/order/details`);
  }

  printDocument(workOrderNumber: string, vendorCode: string, ordertype: string) {
    const url = this.vendorsapiUrl + `/print?ordernumber=${workOrderNumber}&vendorcode=${vendorCode}&ordertype=${ordertype}`;
    return this.http.get(url, { responseType: 'blob' });
  }

    /////////////////////////
  // work Order Services
  /////////////////////////

   // add a work order 
   createWorkOrder(workOrderData: any): Observable<any> {
    return this.http.post<any>(`${this.workordersapiUrl}/workorder`, workOrderData);
  }

  //get all work orders
  getWorkOrders(currentPage:number, pageSize : number): Observable<any> {

    const url = `${this.workordersapiUrl}/workorders?page=${currentPage}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
    //return this.http.get(`${this.workordersapiUrl}/workorders`);
  }

  //get single work order
  getWorkOrder(id: string): Observable<any>{
    return this.http.get(`${this.workordersapiUrl}/getworkorder?workordernumber=${id}`);
  }

  updateWorkOrderDetails(orderData: any, workordernumber: string): Observable<any> {
  
   
    // Pass the headers as the third parameter
    return this.http.put<any>(`${this.workordersapiUrl}/updateworkorder?workordernumber=${workordernumber}`, orderData);
  }

  
  filterWorkOrderByStatus(statusValue: string, currentPage:number, pageSize : number): Observable<any> {
    // Make the HTTP PUT request with the headers
    return this.http.get<any>(`${this.workordersapiUrl}/filterbystatus/${statusValue}?page=${currentPage}&pageSize=${pageSize}`);
  }

  deleteWorkOrder(id: string): Observable<any>{
    //id = encodeURIComponent(id)
    return this.http.delete<any>(`${this.workordersapiUrl}/deleteworkorder?workordernumber=${id}`);
  }

  filterWorkOrdersBySearch(searchQuery: string, currentPage:number, pageSize : number): Observable<any> {
    return this.http.get(`${this.workordersapiUrl}/workorders/filterbysearch?search=${searchQuery}&page=${currentPage}&pageSize=${pageSize}`);
  }

  

  updateWorkOrderStatus(workOrderNumber: string, newStatus : number) : Observable<any>{
    return this.http.put<any>(`${this.workordersapiUrl}/updatestatus?workordernumber=${workOrderNumber}&status=${newStatus}`,{})
  }

  // getting project details

  getProjectDivisions() : Observable<any>{
    return this.http.get(`${this.workordersapiUrl}/getprojectdivisions`);
  }

  getProjectNames(division: string): Observable<any>{
    return this.http.get(`${this.workordersapiUrl}/getprojectnames/${division}`);
  }

  

  /////////////////////////
  // Purchase Order Services
  /////////////////////////


  createPurchaseOrder(orderData:any): Observable<any>{
    return this.http.post<any>(`${this.purchaseapiUrl}/purchaseorder`, orderData)
  }

  deletePurchaseOrder(id: string): Observable<any>{
    return this.http.delete<any>(`${this.purchaseapiUrl}/deletepurchaseorder?purchaseordernumber=${id}`)
  }
  updatePurchaseOrder(id: string , orderData : any): Observable<any>{
    return this.http.put<any>(`${this.purchaseapiUrl}/updatepurchaseorder?purchaseordernumber=${id}`, orderData)
  }

  uploadDocument(jsonData: string, orderType: string, orderNumber: string): Observable<any>{
    return this.http.post<any>(`${this.purchaseapiUrl}/uploadDocument/${orderType}?ordernumber=${orderNumber}`, jsonData);
  }


  downloadUploadedDocument(orderType: string, orderNumber: string): Observable<any>{
    return this.http.get<any>(`${this.purchaseapiUrl}/downloadDocument/${orderType}?ordernumber=${orderNumber}`);
  }



  getPurchaseOrders(currentPage:number, pageSize : number) : Observable<any>{

    const url = `${this.purchaseapiUrl}/purchaseorders?page=${currentPage}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }

  filterPurchaseOrderByStatus(statusValue: string, currentPage:number, pageSize : number): Observable<any> {
    // Make the HTTP PUT request with the headers
    return this.http.get<any>(`${this.purchaseapiUrl}/filterbystatus/${statusValue}?page=${currentPage}&pageSize=${pageSize}`);
  }


  getPurchaseOrder(id:string) : Observable<any>{    
    return this.http.get<any>(`${this.purchaseapiUrl}/getpurchaseorder?purchaseordernumber=${id}`);
    
    }

  updatePurchaseOrderStatus(id: string, newStatus: number) : Observable<any> {
        return this.http.put<any>(`${this.purchaseapiUrl}/updatestatus?purchaseordernumber=${id}&status=${newStatus}`,{});
  }

  filterPurchaseOrdersBySearch(searchQuery: string, currentPage:number, pageSize : number): Observable<any> {
    return this.http.get(`${this.purchaseapiUrl}/purchaseorders/filterbysearch?search=${searchQuery}&page=${currentPage}&pageSize=${pageSize}`);
  }

  /////////////////////////
  // Payment Services
  /////////////////////////

  createPayment(data:any): Observable<any>{
    return this.http.post<any>(`${this.paymentsapiUrl}/payment`, data)
  }

  GenerateFile(listOfPayments: string[], bankName: string): Observable<any> {
    const requestBody = listOfPayments;
    return this.http.post(`${this.paymentsapiUrl}/payment/generatefile?bankName=${bankName}`, requestBody, {
      responseType: 'blob' as 'json' // Set responseType to 'blob' explicitly
    });
  }

   //get all payments
   getPayments(currentPage:number, pageSize : number): Observable<any> {

    const url = `${this.paymentsapiUrl}/payments?page=${currentPage}&pageSize=${pageSize}`;
    return this.http.get(url);
  }

  deletePayment(paymentNumber: string) : Observable<any> {
    return this.http.delete<any>(`${this.paymentsapiUrl}/payment/${paymentNumber}`);
  }


  getPaymentsByRange(requestBody : any): Observable<any> {
    const url = `${this.paymentsapiUrl}/payments/filterbydaterange`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(url, requestBody, { headers: headers });
  }


  filterPaymentsBySearch(searchQuery: string, currentPage: number, pageSize: number): Observable<any> {
    return this.http.get(`${this.paymentsapiUrl}/payments/filterbysearch?search=${searchQuery}&page=${currentPage}&pageSize=${pageSize}`);
  }

 

  getPayment(paymentnumber: string): Observable<any> {
    return this.http.get(`${this.paymentsapiUrl}/payment/${paymentnumber}`);
  }



  updatePaymentDetails(data: any, paymentnumber: string): Observable<any> {
    
    // Pass the headers as the third parameter
    return this.http.put<any>(`${this.paymentsapiUrl}/payment/${paymentnumber}`, data);
  }

  // Update payments status
  updatePaymentStatus(paymentNumbers: string[], newStatus: number): Observable<any> {
  
    
    // Make the HTTP PUT request with the headers and requestBody
    return this.http.put<any>(
      `${this.paymentsapiUrl}/updatepaymentstatus`, // Assuming this is the correct endpoint
      { paymentNumbers, status: newStatus } // Pass requestBody here
    );
  }
  

  GetDataByStatus(requestBody : any): Observable<any> {
    // Make the HTTP PUT request with the headers

    const url = `${this.paymentsapiUrl}/getDataByStatus`;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(url, requestBody, { headers: headers });
    //return this.http.get<any>(`${this.paymentsapiUrl}/getDataByStatus/${statusValue}?page=${currentPage}&pageSize=${pageSize}`);
  }


  
   GetCheckedData(listOfPayments: string[]): Observable<any> {
    
    // Create the request body containing the list of payments
    const requestBody = listOfPayments;
    console.log(requestBody);
    // Make the HTTP POST request with the headers and request body
    return this.http.post<any>(`${this.paymentsapiUrl}/payments/approvedpayments`, requestBody);
  }

    /////////////////////////
  // User Services
  /////////////////////////

 


  getAllUserEmailIds(): Observable<any> {
    return this.http.get(`${this.usersapiUrl}/users/getallemails`);
  }


  createUser(userData:any): Observable<any>{
    return this.http.post<any>(`${this.usersapiUrl}/user`, userData);
  }

   //get all users
   getUsers(currentPage:number, pageSize : number): Observable<any> {
    const url = `${this.usersapiUrl}/users?page=${currentPage}&pageSize=${pageSize}`;
    return this.http.get(url);
  }

  filterUserByStatus(statusValue: string, currentPage:number, pageSize : number): Observable<any> {
    // Make the HTTP PUT request with the headers
    return this.http.get<any>(`${this.usersapiUrl}/filterbystatus/${statusValue}?page=${currentPage}&pageSize=${pageSize}`);
  }


  // Update user status
  updateUserStatus(statusValue: string, userid: string): Observable<any> {
    // Make the HTTP PUT request with the headers and requestBody
    return this.http.put<any>(
      `${this.usersapiUrl}/updatestatus/${userid}?status=${statusValue}`,{} );
  }

  // get single user
  getUser(userId: string): Observable<any> {
    return this.http.get(`${this.usersapiUrl}/user/${userId}`);
  }


  updateUserDetails(userId: string, formData: any): Observable<any> {
    // Pass the headers as the third parameter
    return this.http.put<any>(`${this.usersapiUrl}/user/${userId}`, formData);
  }

  // filter user data 
  filterUsersBySearch(searchQuery: string, currentPage: number, pageSize: number): Observable<any> {
    return this.http.get(`${this.usersapiUrl}/users/filterbysearch?search=${searchQuery}&page=${currentPage}&pageSize=${pageSize}`);
  }

  deleteUser(id: string): Observable<any>{
    //id = encodeURIComponent(id)
    return this.http.delete<any>(`${this.usersapiUrl}/deleteuser/${id}`);
  }


  // get Roles 
  getAllRoles(roletype: any): Observable<any> {
    return this.http.get(`${this.usersapiUrl}/user/roles?roletype=${roletype}`);
  }








  /////////////////////////
  // drop down filters
  /////////////////////////

  //  drop down options 
  getFilterOptions(): Observable<any> {
    // Make HTTP request to fetch vendor options
    return this.http.get(`${this.vendorsapiUrl}/filters`);
  
  }

  FilterByVendorCode(vendorCode: string): Observable<any> {
    // Make HTTP request to fetch vendor options
    return this.http.get(`${this.vendorsapiUrl}/filter/${vendorCode}`);
  
  }

  FilterByBusinessName(businessName: string): Observable<any> {
    // Make HTTP request to fetch vendor options
    return this.http.get(`${this.vendorsapiUrl}/filterByBusiness/${businessName}`);
  
  }



}
