import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private router: Router, private globalServices : GlobalService) { }

  accessDenied(){
    this.globalServices.errorAlert("Access Denied", "You do not have permissions to access this page. Please contact your administrator for further assistance.");
    this.router.navigate(["dashboard/addvendor"]);
  }

}
